import React, { useState } from 'react';
import classnames from 'classnames';
import axios from 'axios';
import { Formik } from 'formik';
import { object, string } from 'yup';

const contactSchema = object().shape({
    name: string().required('Name is required'),
    email: string().email().required('Email is required'),
    phone_number: string().matches(
        /^(1\s*[-/.]?)?(\((\d{3})\)|(\d{3}))\s*[-/.]?\s*(\d{3})\s*[-/.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT])\.?\s*(\d+))*$/,
        'Please enter a valid phone number'
    ),
    reason: string().required('Reason is required'),
    message: string().required('Message is required')
});

const ContactForm = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const submitForm = async (values, { setSubmitting }) => {
        try {
            await axios.request({
                url: `${process.env.GATSBY_CONTACT_URL}/35a90ea2-84e9-47ca-8626-e50043274422`,
                method: 'POST',
                data: {
                    name: values.name,
                    email: values.email,
                    phone_number: values.phone_number,
                    to: values.reason,
                    message: values.message
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                setSubmitting(false);
            }, 3000);
        } catch {
            setError(true);
            setSubmitting(false);
            setTimeout(() => {
                setError(false);
            }, 3000);
        }
    };
    return (
        <Formik
            validationSchema={contactSchema}
            initialValues={{
                name: '',
                email: '',
                phone_number: '',
                reason: '',
                message: ''
            }}
            onSubmit={submitForm}
        >
            {({ values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting }) => (
                <form id="contact_form" autoComplete="on" noValidate onSubmit={handleSubmit}>
                    <div className="form-group required">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className={classnames('form-control', {
                                'is-invalid': errors.name && touched.name
                            })}
                            onChange={handleChange}
                            value={values.name}
                            required
                            autoComplete="name"
                        />
                        {errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>
                    <div className="form-group required">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className={classnames('form-control', {
                                'is-invalid': errors.email && touched.email
                            })}
                            onChange={handleChange}
                            value={values.email}
                            required
                            autoComplete="email"
                        />
                        {errors.email && touched.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            className={classnames('form-control', {
                                'is-invalid': errors.phone_number && touched.phone_number
                            })}
                            autoComplete="tel"
                            onChange={handleChange}
                            value={values.phone_number}
                        />
                        {errors.phone_number && touched.phone_number && (
                            <div className="invalid-feedback">{errors.phone_number}</div>
                        )}
                    </div>
                    <div className="form-group required">
                        <label htmlFor="reason">Reason for Inquiry</label>
                        <div className="select-wrapper">
                            <select
                                name="reason"
                                id="reason"
                                className={classnames('custom-select', {
                                    'is-invalid': errors.reason && touched.reason
                                })}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reason}
                                required
                            >
                                <option value="">Select a reason</option>
                                <option value="digital_distribution">Digital Distribution</option>
                                <option value="audioengine">AudioEngine</option>
                                <option value="voices">Findaway Voices</option>
                            </select>
                        </div>
                        {errors.reason && touched.reason && <div className="invalid-feedback">{errors.reason}</div>}
                    </div>
                    <div className="form-group required">
                        <label htmlFor="message">Message</label>
                        <textarea
                            name="message"
                            id="message"
                            rows="8"
                            cols="40"
                            className={classnames('form-control', {
                                'is-invalid': errors.message && touched.message
                            })}
                            onChange={handleChange}
                            value={values.message}
                            required
                        />
                        {errors.message && touched.message && <div className="invalid-feedback">{errors.message}</div>}
                    </div>
                    <div className="txt-center mb-5">
                        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                        {success && <div className="txt-green">Thank you for contacting Findaway!</div>}
                        {error && (
                            <div className="txt-red">Whoops! It looks like something broke. Please try again.</div>
                        )}
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default ContactForm;

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const SiteMetadata = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    image
                    siteUrl
                    author
                }
            }
        }
    `);

    const { title, description, image, siteUrl, author } = data.site.siteMetadata;

    const metaDescription = description;
    const metaTags = [
        {
            name: `description`,
            content: metaDescription
        },
        {
            property: `og:title`,
            content: title
        },
        {
            property: `og:description`,
            content: metaDescription
        },
        {
            property: `og:type`,
            content: `website`
        },
        {
            property: `og:image`,
            content: image
        },
        {
            property: 'og:url',
            content: siteUrl
        },
        {
            name: `twitter:card`,
            content: `summary`
        },
        {
            name: `twitter:creator`,
            content: author
        },
        {
            name: `twitter:title`,
            content: title
        },
        {
            name: `twitter:description`,
            content: metaDescription
        },
        {
            name: `twitter:image`,
            content: image
        }
    ];

    return (
        <>
            <title>
                {title} - {description}
            </title>
            {metaTags.map((tag) => (
                <meta key={tag.name || tag.property} {...tag} />
            ))}

            {/* <!-- For old IEs --> */}
            <link rel="shortcut icon" href="favicon.ico" />

            {/* <!-- For new browsers - multisize ico  --> */}
            <link rel="icon" type="image/x-icon" sizes="16x16 32x32" href="favicon.ico" />

            {/* <!-- For iPad with high-resolution Retina display running iOS ≥ 7: --> */}
            <link rel="apple-touch-icon" sizes="152x152" href="favicon-152-precomposed.png" />

            {/* <!-- For iPad with high-resolution Retina display running iOS ≤ 6: --> */}
            <link rel="apple-touch-icon" sizes="144x144" href="favicon-144-precomposed.png" />

            {/* <!-- For iPhone with high-resolution Retina display running iOS ≥ 7: --> */}
            <link rel="apple-touch-icon" sizes="120x120" href="favicon-120-precomposed.png" />

            {/* <!-- For iPhone with high-resolution Retina display running iOS ≤ 6: --> */}
            <link rel="apple-touch-icon" sizes="114x114" href="favicon-114-precomposed.png" />

            {/* <!-- For iPhone 6+ --> */}
            <link rel="apple-touch-icon" sizes="180x180" href="favicon-180-precomposed.png" />

            {/* <!-- For first- and second-generation iPad: --> */}
            <link rel="apple-touch-icon" sizes="72x72" href="favicon-72-precomposed.png" />

            {/* <!-- For non-Retina iPhone, iPod Touch, and Android 2.1+ devices: --> */}
            <link rel="apple-touch-icon" sizes="57x57" href="favicon-57.png" />

            {/* <!-- For Old Chrome --> */}
            <link rel="icon" sizes="32x32" href="favicon-32.png" />

            {/* <!-- For IE10 Metro --> */}
            <meta name="msapplication-TileColor" content="#de4b2a" />
            <meta name="theme-color" content="#333333" />
            <meta name="msapplication-TileImage" content="favicon-144.png" />

            {/* <!-- Chrome for Android --> */}
            <link rel="manifest" href="manifest.json" />
            <link rel="icon" sizes="192x192" href="favicon-192.png" />
        </>
    );
};

import React from 'react';
import Layout from '../components/layout';
import { SiteMetadata } from '../components/seo';
import ArrowDown from '../svg/arrow-down.svg';
import headerVideo from '../images/homepage_movie_2022.mp4';
import posterImage from '../images/home/poster_image.png';
import ContactForm from '../components/ContactForm';

const IndexPage = () => {
    return (
        <Layout>
            <div className="page-home">
                <div className="cta-wrapper">
                    <div className="content">
                        <h1>The World's Audiobook Partner</h1>
                        <h2>Findaway is now part of Spotify</h2>
                    </div>
                    <div className="background-wrapper">
                        <video
                            autoPlay={true}
                            className="bg-video"
                            id="myVideo"
                            src={headerVideo}
                            poster={posterImage}
                            muted
                            loop
                        />
                        <div className="video-overlay"></div>
                    </div>
                    <div className="more">
                        <a
                            href="#contact"
                            className="btn btn-link"
                            onClick={(e) => {
                                e.preventDefault();
                                document.querySelector('#contact').scrollIntoView({
                                    behavior: `smooth`
                                });
                            }}
                        >
                            <ArrowDown />
                        </a>
                    </div>
                </div>
                <div id="contact" className="py-20">
                    <div className="container">
                        <h2 className="mb-5">Contact Us.</h2>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IndexPage;

export const Head = () => <SiteMetadata />;
